export const QUESTIONNAIRE_TYPES = {
  EXAM: 1,
  FORM: 2,
};

export const QUESTIONNAIRE_TEMPLATE_TYPES = {
  CREATE_FROM_SCRATCH: 1,
  SINGLE_CORRECT: 2,
  MULTIPLE_CORRECT: 3,
  SHORT_COURSE_EVALUATION: 4,
  LONG_COURSE_EVALUATION: 5,
  SHORT_INSTRUCTOR_EVALUATION: 6,
  LONG_INSTRUCTOR_EVALUATION: 7,
  PRE_EVENT_FEEDBACK_FORM: 8,
  POST_EVENT_FEEDBACK_FORM: 9,
};

export const QUESTION_TYPES = {
  TEXT_QUESTION: 1,
  SINGLE_SELECT: 2,
  MULTI_SELECT: 3,
  CALENDAR: 4,
  FILE: 5,
  STAR_RATING: 6,
  FIVE_NUMBER_RATING: 7,
  TEN_NUMBER_RATING: 8,
};

export const QUESTION_CHANGE_STATES = {
  NO_CHANGE: 0,
  UPDATED: 1,
  NEW_ADDED: 2,
  DELETED: 3,
};

export const QUESTIONNAIRE_FORM_KEYS = {
  contentUid: "content_uid",
  questionairreUid: "uuid",
  questionnaireType: "questionairre_type",
  questionnaireTemplateType: "template_type",
  questions: "questions",
  totalQuestions: "total_questions",
  scoringType: "scoring_type",
  usageCount: "usage_count",
  currentCourseUsageCount: "current_course_usage_count",
  otherCoursesUsageCount: "other_courses_usage_count",
};

export const QUESTION_KEYS = {
  changeType: "change_type",
  question: "question",
  questionType: "question_type",
  sequenceNo: "sequence_no",
  isMandatory: "is_mandatory",
  options: "options",
  questionUid: "question_uid",
  answer: "answer", // will be used in responses
  totalMarks: "marks",
  markReceived: "mark_received",

  metadata: "type_metadata",
  metadataKeys: {
    textLimit: "text_limit",
    uploadType: "upload_type",
  },
};

export const QUESTION_OPTION_KEYS = {
  changeType: "change_type",
  option: "option",
  isCorrect: "is_correct",
  sequenceNo: "sequence_no",
  isSelected: "is_selected",
  optionUid: "option_uid",
};

export const SETTINGS_KEYS = {
  title: "title",
  description: "description",
  contentUid: "content_uid",
  totalMarks: "total_marks",
  totalQuestions: "total_questions",
  correctAnswerMark: "correct_answer_mark",
  incorrectAnswerMark: "incorrect_answer_mark",
  passingMarks: "passing_marks",
  isReattemptAllowed: "is_reattempt_allowed",
  noOfAttemptAallowed: "no_of_attempts_allowed",
  duration: "duration",
  templateType: "template_type",
  commentVisibility: "comment_visibility",
  enable_hints: "enable_hints",
  auto_open_hints: "auto_open_hints",
  hints: "hints",
};

export const STRUCTURE_KEYS = {
  lesson: "lesson",
  sections: "sections",
};

export const RECORDED_CONTENT_KEYS = {
  TYPE: "type",
};
