export const plans_apis = {
  add_plan: "host/create/dropdown/plan",
  update_plan: "host/update/dropdown/plan",
  publish_plan: "host/publish/dropdown/plan",
  delete_plan: "host/delete/dropdown/plan",
  get_plans: "host/list/dropdown/plan",
  get_plan_details: "host/dropdown",
  duplicate_plan: "host/duplicate/dropdown/plan",
  update_listing_description: "host/update/listing/description",
  seo_plan: "host/dropdown",
};

export const BATCH_PARTICIPANTS_API = "host/creator/batch/participants";
export const LISTING_PARTICIPANTS_API = "host/creator/listings/participants";
