import { getSubdomain } from "features/Account/utils/getCreatorPageDetails";
import { deepEqual } from "utils/Utils";
import planConstants from "./Constants";
import { is_empty } from "utils/validations";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dataProvider } from "data";
import { plans_apis } from "./moduleAPI";
import { EXLY_SCHEDULELIST_SET_ACTIVE_PLANS_LIST } from "ui/pages/schedule/redux/actions";

export const formErrorKeys = {
  EMPTY_OFFERING: "emptyOfferingError",
};

export const formErrorMessageFormatter = (errors) =>
  errors[formErrorKeys.EMPTY_OFFERING];

export const validators = {
  offeringSelected: (formValues, page) => {
    const isBasicDetailPage = page === 0;
    if (!isBasicDetailPage) return;

    const { listing_map } = formValues;
    if (is_empty(listing_map))
      return { [formErrorKeys.EMPTY_OFFERING]: "Add atleast one offering." };
  },
};

/**
Updates the listing map based on the selected offering and plan listing map.
sets "change_state" to "No change", "Updated", "New Added", and "Deleted"
@param {Array} plan_listing_map - The original plan listing map.
@param {Array} selected_offering - The selected offering.
@returns {Array} - The updated and transformed plan listing map.
*/
export const updateListingMap = ({ plan_listing_map, selected_offering }) => {
  const newPlanListingMap = [];
  const serialize = true;

  const frontendObjMap = new Map();
  for (const frontendObj of selected_offering) {
    const uuid = frontendObj?.plan_listing_map_uuid;
    if (uuid) {
      frontendObjMap.set(uuid, frontendObj);
    }
  }

  for (const obj of plan_listing_map) {
    const uuid = obj?.plan_listing_map_uuid;

    if (uuid) {
      if (frontendObjMap.has(uuid)) {
        const frontendObj = frontendObjMap.get(uuid);

        if (deepEqual(obj, frontendObj, serialize)) {
          frontendObj.change_state =
            planConstants.offering_change_state.NO_CHANGE;
        } else {
          frontendObj.change_state =
            planConstants.offering_change_state.UPDATED;
        }

        newPlanListingMap.push(frontendObj);
        frontendObjMap.delete(uuid);
      } else {
        obj.change_state = planConstants.offering_change_state.DELETED;
        newPlanListingMap.push(obj);
      }
    }
  }

  for (const [uuid, frontendObj] of frontendObjMap.entries()) {
    frontendObj.change_state = planConstants.offering_change_state.NEW_ADDED;
    newPlanListingMap.push(frontendObj);
    frontendObjMap.delete(uuid);
  }

  const transformedPlanListingMap = newPlanListingMap.map((value) => {
    return {
      priority: value.priority,
      change_state: value?.change_state,
      listing_uuid: value?.plan_listing_map_uuid,
      is_recommended: value?.is_recommended,
      title: value?.title,
      price_per_head: value?.price_per_head,
    };
  });

  return transformedPlanListingMap;
};

/**
Updates the value of a query string parameter in a given URI.
@param {string} uri - The original URI.
@param {string} key - The query string parameter key.
@param {string} value - The new value for the query string parameter.
@returns {string} - The updated URI with the modified query string parameter.
*/
export const updateQueryStringParameter = (uri, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

export const useFetchAllActivePlanPagesList = () => {
  const allActivePlanPagesList = useSelector(
    (state) => state.schedule.plan.activePlans
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!allActivePlanPagesList?.length) {
      dataProvider
        .custom_request(plans_apis.get_plans, "GET", {
          page: "all",
          status: planConstants.STATUS_ENUM.ACTIVE,
        })
        .then((response) => {
          const activePlansList = response?.data?.dropdown_plans || [];

          dispatch({
            type: EXLY_SCHEDULELIST_SET_ACTIVE_PLANS_LIST,
            payload: activePlansList.map((i) => ({
              ...i,
            })),
          });
        });
    }
  }, []);
  return {
    allActivePlanPagesList,
  };
};

export const fetchSEODataForPlanListing = async (uuid) => {
  const subDomain = getSubdomain();
  return dataProvider.custom_request(
    `${plans_apis.seo_plan}/${subDomain}/${uuid}`,
    "GET"
  );
};

export const useFetchPlanListings = (uuid) => {
  const subDomain = getSubdomain();
  const [plan_listings, set_plan_listings] = useState(null);
  useEffect(() => {
    dataProvider
      .custom_request(
        `${plans_apis.get_plan_details}/${subDomain}/${uuid}`,
        "GET"
      )
      .then((response) => {
        const listings = response?.data?.listings;
        set_plan_listings(listings);
      })
      .catch((err) => console.log("Err while fetching the plan Listings", err));
  }, [uuid]);

  return {
    plan_listings,
  };
};
